import { makeStyles, Theme } from '@material-ui/core';
import ChecksCheckboxGroup from '../order-form/ChecksCheckboxGroup';
import InfectionCheckboxGroup from '../order-form/InfectionCheckboxGroup';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import LanguageRadioGroup from '../order-form/LanguageRadioGroup';
import KgNrField from '../order-form/KgNrField';
import PhoneField from '../order-form/PhoneField';
import { Props } from './OrderCreateFormContainer';
import UserAssignmentFieldContainer from '../order-form/UserAssignmentFieldContainer';

const useStyles = makeStyles((theme: Theme) => ({
  formBlock: {
    marginTop: theme.spacing(4),
  },
}));

export default function OrderCreateForm({ formState, setKgNr, setPhone, setInfections, setLanguage, disabled }: Props) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
      // Apply kgNr and phone passed along by the PIS when linking to this page
      const searchParams = new URLSearchParams(location.search);
      const kgNr = searchParams.get('kgnr');
      const phone = searchParams.get('phone');
      if (kgNr) setKgNr(kgNr);
      if (phone) setPhone(phone);
  }, [location.search, setKgNr, setPhone]);

  return (
    <>
      <KgNrField kgNrField={formState.kgNr} setKgNr={setKgNr} disabled={disabled} />
      <PhoneField phoneField={formState.phone} setPhone={setPhone} disabled={disabled} />
      <div className={classes.formBlock}>
        <ChecksCheckboxGroup formField={formState.infections} setValues={setInfections} disabled={disabled} />
      </div>
      <div className={classes.formBlock}>
        <InfectionCheckboxGroup formField={formState.infections} setValues={setInfections} disabled={disabled} />
      </div>
      <div className={classes.formBlock}>
        <LanguageRadioGroup formField={formState.language} setLanguage={setLanguage} disabled={disabled} />
      </div>
      <div className={classes.formBlock}>
        <UserAssignmentFieldContainer assignedEmployeeId={formState.assignedEmployeeId.value} disabled={disabled} />
      </div>
    </>
  );
}
